const Details = ({eventDetails, faq}) => {
    const handleClick = (e) => {
        console.log("### clicked", e.target.classList, e.target.nextElementSibling.classList)
        
        const currentButton = e.target.classList

        if (currentButton.contains('expanded')) {
            currentButton.remove('expanded')
        } else {
            currentButton.add('expanded')
        }

        const panelClass = e.target.nextElementSibling.classList;

        if (panelClass.contains('activeAccordian')) {
            panelClass.remove('activeAccordian')
        } else {
            panelClass.add('activeAccordian')
        }
    }
    
    return (
        <div class="w-full cool-gray-bg">
            {
                eventDetails &&    <div class="container p-24-LR pt-20" id="eventDetailsSection">
                <h1 class="arizona-blue text-5xl font-extrabold pb-8">EVENT DETAILS</h1>
                <p class="arizona-blue font-bold">February 29 — March 1, 2024</p>
                <p class="arizona-blue font-bold">University of Arizona, Tucson, Arizona</p>
                <p class="pt-4">This is an overnight trip and complimentary accommodations will be provided at <a class="brand" target="_blank" href="https://www.marriott.com/en-us/hotels/tusmp-tucson-marriott-university-park/overview/">Tucson Marriott University Park</a> on University Blvd for you and up to two parent(s)/guardian(s). We encourage them to take part in the activities.</p> 
                <p class="pt-4"> Please <a target="_blank" href="https://slate.admissions.arizona.edu/register/flinn-finalist-2024" class="brand inline">RSVP</a> by February 23. If you have any questions, contact Jessica Salata at <a class="brand" href="mailto:jessicadelfs@arizona.edu">jessicadelfs@arizona.edu</a></p>
                <div class="text-white mt-4 pt-3 pb-3 pl-20 pr-20 font-extrabold text-center arizona-red-bg w-60 rounded-3xl max-sm:pl-10 max-sm:pr-10 ">
                    <a target="_blank" href="https://slate.admissions.arizona.edu/register/flinn-finalist-2024">RSVP</a>
                </div>
                <p class="pt-4">Can’t make it to our overnight event but still want to tour the Honors Village? Contact us at <a class="brand" href="mailto:honors@arizona.edu">honors@arizona.edu</a> and we will gladly arrange a customized visit experience.</p>
                <h2 class="mt-10 arizona-blue text-3xl font-bold">Agenda & Events</h2>
                <p class="pt-4 pb-4">Additional details will be shared soon, so please check back regularly for the latest updates.</p>
                <div class="accordion">
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Thursday, February 29</button>
                    <div class="accordion-content">
                        <div class="md:flex">
                            <div class="flex ">
                            <div class="redBar">
    
                                </div>
                                <div class="schedule">
                                    <p class="brand">3:00pm</p>
                                    <p>Check In Begins at Tucson Marriott University Park</p>
                                </div>
                            </div>
                            <div class="flex">
                            <div class="redBar">
    
                                </div>
                                <div class="schedule">
                                    <p class="brand">4:30pm</p>
                                    <p>Depart Hotel for Campus</p>
                                </div>
                            </div>
                            <div class="flex">
                            <div class="redBar">
    
                                </div>
                                <div class="schedule">
                                    <p class="brand">5:00pm</p>
                                    <p>Welcome & What to Expect Information Session</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="md:flex md:mt-6">
                            <div class="flex ">
                            <div class="redBar">
    
                                </div>
                                <div class="schedule">
                                    <p class="brand">6:30pm</p>
                                    <p>Welcome Dinner at the Health Sciences Innovation Building</p>
                                </div>
                            </div>
                            <div class="flex">
                            <div class="redBar">
    
                                </div>
                                <div class="schedule">
                                    <p class="brand">8:30pm</p>
                                    <p>Parent & Staff Social Hour</p>
                                </div>
                            </div>
                            <div class="flex">
                            <div class="redBar">
    
                                </div>
                                <div class="schedule">
                                    <p class="brand">8:30pm</p>
                                    <p>Student Social with Current UA Flinn Scholars</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordion-bottom">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Friday, March 1</button>
                    <div class="accordion-content">
                        <div class="md:flex">
                                <div class="flex ">
                                    <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">8:30am</p>
                                        <p>Welcome & Breakfast at the Honors Village</p>
                                    </div>
                                </div>
                                <div class="flex">
                                <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">9:00am</p>
                                        <p>W.A. Franke Honors College Information Session</p>
                                    </div>
                                </div>
                                <div class="flex">
                                <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">10:00am</p>
                                        <p>Integrated Learning Mindset & Practice with Dr. John Pollard</p>
                                    </div>
                                </div>
                        </div>
                        <div class="md:flex md:mt-6 ">
                                <div class="flex ">
                                    <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">11:00am</p>
                                        <p>Honors Village Tour</p>
                                    </div>
                                </div>
                                <div class="flex">
                                <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">11:30am</p>
                                        <p>Lunch at ‘85 North</p>
                                    </div>
                                </div>
                                <div class="flex">
                                <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">12:30–1:30pm</p>
                                        <p>Campus Exploration</p>
                                    </div>
                                </div>
                        </div>
                        <div class="md:flex md:mt-6">
                                <div class="flex">
                                    <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">2:00–3:15pm</p>
                                        <p>College Experience #1</p>
                                    </div>
                                </div>
                                <div class="flex">
                                <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">3:30–4:45pm</p>
                                        <p>College Experience #2</p>
                                    </div>
                                </div>
                                <div class="flex">
                                <div class="redBar">
    
                                    </div>
                                    <div class="schedule">
                                        <p class="brand">5:00pm</p>
                                        <p>Closing Reception</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    
                    
                </div>
                </div>
    
                <h3 class="mt-6 text-3xl font-bold arizona-blue">Welcome Dinner</h3>
                <p class="pt-4">
                    We are excited to meet you and your guests at a welcome dinner hosted on <strong>February 29, 2024 at 6:30PM.</strong> Mingle with college and campus leadership as well as current students in the academic areas you are most interested in during this exciting portion of the event. Business casual attire is recommended for the Welcome Dinner. More information about our Welcome Dinner agenda and seating charts coming soon!
                </p>
    
            </div>
            }
            {
                faq && 
                <div class="container p-24-LR pt-20">
                <h1 class="arizona-blue text-5xl font-extrabold pb-8">FAQS</h1>

                <div class="accordion">
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">What is the Flinn Finalist overnight event?</button>
                    <div class="accordion-content">
                       <p>Hosted by the University of Arizona W.A. Franke Honors College, this special overnight event is where Flinn Finalists from across the state come together to learn about our R1 research university, network with their peers and mentors, and preview what it’s like to be an Arizona Wildcat. The event is held over the course of two days so that you can fully experience everything our university and W.A. Franke Honors College has to offer.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Will I get a tour of the Honors Village?</button>
                    <div class="accordion-content">
                       <p>Yes! We can’t wait to show you around our state-of-the-art facility. The Honors Village features contemporary living spaces, as well as on-site classrooms, study spaces, and dining – all exclusively designed for honors students.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Who can I bring with me to the overnight event?</button>
                    <div class="accordion-content">
                       <p>Flinn Finalists may bring up to two parents and/or guardians as guests for the event. Your guest(s) are encouraged to participate in all activities with you.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">What should I bring?</button>
                    <div class="accordion-content">
                       <p>Dress comfortably to tour campus and the Honors Village. Business casual attire is recommended for the Welcome Dinner. You should plan to bring any other essentials you will need during your overnight stay. All meals will be provided.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">I can’t afford the overnight event. What should I do?</button>
                    <div class="accordion-content">
                       <p>
                       The Flinn Scholar Finalist Visit is a free event. Hotel accomodations, parking, and all meals are covered by the University with no charge to you or your guests for any portion of the event. If transportation to Tucson, AZ creates a burden, please reach out to Jessica Salata at <a class="brand" href="mailto:jessicadelfs@arizona.edu">jessicadelfs@arizona.edu</a> to assist with transportation to the event.
                        </p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">What if I'm unable to attend the full event?</button>
                    <div class="accordion-content">
                       <p>If you can only attend a portion of the overnight event, please contact Jessica Salata at <a class="brand inline" href="tel:520-621-5408">(520) 621-5408 </a>or <a class="brand" href="mailto:jessicadelfs@arizona.edu">jessicadelfs@arizona.edu</a> to coordinate your arrangements.</p>
                        <br/>
                        <p>If you are unable to attend the entire overnight event, we would still love to meet you and arrange a visit experience. Please contact us at <a class="brand" href="mailto:honors@arizona.edu."></a>honors@arizona.edu.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Do I have to stay at the hotel even if I live in Tucson?</button>
                    <div class="accordion-content">
                       <p>We recommend that all event attendees take advantage of the hotel accommodations to enjoy their full experience as a Flinn Finalist.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Do I need to apply to the W.A. Franke Honors College?</button>
                    <div class="accordion-content">
                       <p>In recognition of your status as a Flinn Finalist, you will automatically be admitted into the W.A. Franke Honors College. All you have to do is <a class="brand" target="_blank" href="https://slate.admissions.arizona.edu/apply/"></a>submit your application to the University of Arizona. Once you have been admitted to the university, you can officially become an Arizona Wildcat, you can finish enrolling in your <a target="_blank" class="brand" href="https://nextsteps.arizona.edu/nextsteps">Next Steps Center</a>.</p>
                    </div>
                </div>
                <div class="accordion-item accordion-bottom">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Who should I contact if I still have questions?</button>
                    <div class="accordion-content">
                       <p>Please contact Jessica Salata at <a class="brand" href="tel:520-621-5408">(520) 621-5408</a> or <a class="brand" href="jessicadelfs@arizona.edu"></a> with any questions. If you’re interested in learning more about the University of Arizona Honors College, visit us at <a class="brand" target="_blank" href="honors.arizona.edu.">honors.arizona.edu.</a></p>
                    </div>
                </div>
                </div>
                </div>
            }
     
        </div>
    );
}
export default Details;