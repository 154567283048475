import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

const Gallery = ({home, vip, image1, image2, image3, finalist, finalistHonors}) => {

    return (
        <div>
            <div class="cool-gray-bg pb-20">
            {
                home &&
                    // <div class="flex pt-20 container justify-between flex-wrap max-[1152px]:justify-center">
                    <div class="md:flex pt-20 container md:justify-between p-24-LR " >

                                <Link to="/profiles">
                                <div class="pb-3 pr-3 relative ml-auto mr-auto max-w-[380px]">

                                <img class=" max-h-[420px] z-0 " src={image1}></img>
                                <div class="link-overlay max-[818px]:text-lg">
                                Meet Franke Flinns
                                </div>
                                </div>

                                </Link>
                           
                      
                      <Link to="/vip">
                        <div class="pb-3 pr-3 relative ml-auto mr-auto max-w-[380px]">
                            <img class=" max-h-[420px] ml-auto mr-auto" src={image2}></img>
                            <div class="link-overlay max-[818px]:text-lg">
                            Exclusive Privileges
                                </div>
                        </div>
                      </Link>
                      
                      <Link to="/life">
                        <div class="pb-3 pr-3 relative ml-auto mr-auto max-w-[380px]">
                              <img class=" max-h-[420px] ml-auto mr-auto" src={image3}></img>
                              <div class="link-overlay max-[818px]:text-lg">
                              Life at Arizona
                                </div>
                        </div>
                      </Link>
                  </div>
            }
            {
                vip &&
                <>
                <div class="pt-20 container p-24-LR max-lg:text-center ">
                    <p class="arizona-blue font-bold text-4xl pb-4 pr-4 lg:max-w-[420px]">
                    A Peek Inside the Honors Village
                    </p>
                    <p class="pb-8">
                    Imagine an immersive learning experience that plugs you into the resources, people and opportunities to help you excel. Welcome to the Honors Village, a living and learning community unlike any you’ve ever seen.
                    </p>
                </div>
                    <div class="p-24-LR flex container justify-between flex-wrap">
                      <div class="w-full max-lg:text-center lg:basis-1/3 pb-3">
                          <img class="max-lg:ml-auto max-lg:mr-auto max-h-[215px]" src={image1}></img>
                          <div>
                          <p class="azurite font-bold text-2xl pt-5">Connect</p>
                          <p class="pt-2">with the Franke Honors community in state-of-the art facilities with amazing on-site amenities.</p>
                          </div>
                      </div>
                      <div class="w-full max-lg:text-center lg:basis-1/3 pb-3">
                          <img class="max-lg:ml-auto max-lg:mr-auto max-h-[215px]" src={image2}></img>
                          <div>
                          <p class="azurite font-bold text-2xl pt-5">Work</p>
                          <p class="pt-2">with fellow honors students and faculty in innovative collaboration spaces and classrooms — without stepping foot outside the building.</p>
                            </div>
                      </div>
                      <div class="w-full max-lg:text-center lg:basis-1/3 pb-3">
                          <img class="max-lg:ml-auto max-lg:mr-auto max-h-[215px]" src={image3}></img>
                          <div>
                          <p class="azurite font-bold text-2xl pt-5">Play</p>
                          <p class="pt-2">and recharge in modern lounge spaces, grab a bite to eat at the ’85 North in-dorm dining or unwind at a recreation center next door.</p>
                    
                          </div>
      </div>
                  </div>
                  <div class="flex justify-center">
                  <div class="mt-5 text-white pt-3 pb-3 font-extrabold text-center arizona-red-bg w-72 rounded-3xl">
                    <a href="https://housing.arizona.edu/dorms/honors-village">Preview the Honors Village</a>
        </div>
                  </div>
                </>
            }
           
    </div>
    {
                finalist &&
                <div class="bg-white pt-20 pb-20">
                <div class="container p-24-LR max-lg:text-center ">
                    <p class="arizona-blue font-extrabold text-4xl sm:text-5xl pb-4 pr-4 lg:max-w-[567px]">
                    AROUND TUCSON
                    </p>
                    <p class="pb-8 lg:max-w-[792px]">
                    Our laid-back college hometown offers something for everyone. If you’re visiting from out of town, there are plenty of sights to see while you’re here.</p>
                </div>
                    <div class="p-24-LR flex container justify-between flex-wrap">
                      <div class="w-full max-lg:text-center lg:basis-1/3 pb-3 pr-8">
                          <img class="max-lg:ml-auto max-lg:mr-auto max-h-[240px]" src={image1}></img>
                          <div>
                          <p class="azurite font-bold text-2xl pt-5">University Boulevard</p>
                          <p class="pt-2">Just west of campus, you’ll find one of the most popular hangout spots for Arizona Wildcats. University Boulevard is lined with plenty of shops and restaurants. It’s also where students enjoy pep rallies before home football games.</p>
                          </div>
                      </div>
                      <div class="w-full max-lg:text-center lg:basis-1/3 pb-3 pr-8">
                          <img class="max-lg:ml-auto max-lg:mr-auto max-h-[240px]" src={image2}></img>
                          <div>
                          <p class="azurite font-bold text-2xl pt-5">Downtown & Fourth Avenue</p>
                          <p class="pt-2">Historic landmarks meet contemporary venues in Downtown Tucson, while the city’s famous Fourth Avenue has a vibe all its own. Whether you’re looking for upscale dining, local artisans, or your new favorite hole-in-the-wall shop, you’ll find it here.</p>
                            </div>
                      </div>
                      <div class="w-full max-lg:text-center lg:basis-1/3 pb-3 pr-8">
                          <img class="max-lg:ml-auto max-lg:mr-auto max-h-[240px]" src={image3}></img>
                          <div>
                          <p class="azurite font-bold text-2xl pt-5">Sabino Canyon & Mt. Lemmon</p>
                          <p class="pt-2">Have some time to spare? Soak up Tucson’s breathtaking desert landscapes with a hike at Sabino Canyon. Or, take the drive up Mount Lemmon to enjoy colder weather (yes, it does snow there) and experience the city from an entirely new perspective.</p>
                    
                          </div>
            </div>
            
                  </div>
                </div>
            }
            {
                finalistHonors && 
                <div class="bg-white pt-20 pb-20 p-24-LR container">
                    <p class="text-center lg:text-left arizona-blue font-extrabold text-4xl sm:text-5xl pb-4 pr-4 lg:max-w-[567px]">
                    WHY ARIZONA HONORS?
                   </p>

                <div class="lg:flex pt-20 container justify-between">
                                <div class="max-w-[380px] max-lg:ml-auto max-lg:mr-auto lg:basis-1/3 mb-6">

                                <a target="_blank" href="https://frankehonors.arizona.edu/why-honors/academic-opportunities?_gl=1*bmv3n6*_ga*MTY3OTg2OTk3MS4xNjg2NzU2Nzg4*_ga_7PV3540XS3*MTcwMDUxMDIxMy45Mi4xLjE3MDA1MTI3MjguMTUuMC4w">
                                <div class="max-lg:text-center pb-3 pr-3 relative">

                                <img class="w-full z-0 max-lg:max-h-[350px] ml-auto mr-auto" src={image1}></img>
                                <div class="link-overlay">
                                    <p class="click">Exclusive Benefits</p>
                                </div>
                                </div>

                                </a>
                                <p class="pt-2 pr-8">As an automatic member of the W.A. Franke Honors College, you will enjoy</p>

                                </div>
                           
                      
                      <div class="max-w-[380px] max-lg:ml-auto max-lg:mr-auto lg:basis-1/3 mb-6">
                      <a target="_blank" href="https://frankehonors.arizona.edu/why-honors/honors-community?_gl=1*1ngne3q*_ga*MTY3OTg2OTk3MS4xNjg2NzU2Nzg4*_ga_7PV3540XS3*MTcwMDUxMDIxMy45Mi4xLjE3MDA1MTI4NDIuNjAuMC4w">
                        <div class="max-lg:text-center pb-3 pr-3 relative">
                            <img class=" w-full z-0 max-lg:max-h-[350px] ml-auto mr-auto" src={image2}></img>
                            <div class="link-overlay">
                                <p class="click">Supportive Community</p>
                            </div>
                        </div>
                      </a>
                           <p class="pt-2 pr-8">Our vibrant community is unlike any other. In fact, it's one of the top reasons why Flinn Scholars choose to become Wildcats.</p>

                      </div>
                      
                      <div class=" max-w-[380px] max-lg:ml-auto max-lg:mr-auto lg:basis-1/3 mb-4">
                      <a target="_blank" href="https://www.youtube.com/watch?v=2gOh1UXOr9o&list=PLMrsYOKrP2J8gybS9fs80MynZdMiD0bTT&index=5&t=18s">
                        <div class="max-lg:text-center pb-3 pr-3 relative ">
                              <img class="w-full z-0 max-lg:max-h-[350px] ml-auto mr-auto" src={image3}></img>
                              <div class="link-overlay">
                                    <p class="click">Honors Village</p>
                                </div>
                        </div>
                      </a>
                      <p class="pt-2 pr-8">It's so much more than a typical dorm. Check out this student-led video tour for a sneak peak of the Honors Village.</p>

                      </div>
                  </div>
                  </div>
            }
        </div>
        
    )

}

export default Gallery;